export default function IconInstagram({ className }: { className?: string }) {
  return (
    <svg fill="none" viewBox="0 0 24 24" className={`fill-current ${className}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.053.072C8.333.013 8.74 0 12 0s3.668.014 4.946.072c1.279.058 2.151.262 2.915.557.8.303 1.525.775 2.125 1.386a5.862 5.862 0 011.383 2.124c.297.764.5 1.636.559 2.913C23.987 8.333 24 8.742 24 12c0 3.26-.014 3.668-.072 4.947-.058 1.277-.262 2.15-.559 2.913a5.887 5.887 0 01-1.383 2.126 5.885 5.885 0 01-2.125 1.383c-.764.297-1.636.5-2.913.559-1.28.059-1.688.072-4.948.072-3.26 0-3.668-.014-4.947-.072-1.277-.058-2.15-.262-2.913-.559a5.889 5.889 0 01-2.126-1.383 5.882 5.882 0 01-1.385-2.125c-.295-.764-.498-1.636-.557-2.913C.013 15.666 0 15.258 0 12c0-3.26.014-3.668.072-4.946.058-1.279.262-2.151.557-2.915.302-.8.775-1.526 1.386-2.125A5.882 5.882 0 014.139.629C4.903.334 5.775.131 7.052.072zm9.796 2.16c-1.265-.058-1.645-.07-4.849-.07s-3.584.012-4.85.07c-1.17.053-1.805.249-2.228.413-.56.219-.96.477-1.38.897a3.722 3.722 0 00-.897 1.38c-.164.423-.36 1.058-.413 2.229-.058 1.265-.07 1.645-.07 4.849s.012 3.584.07 4.85c.053 1.17.249 1.804.413 2.228.192.52.499.992.897 1.38.387.398.86.705 1.38.897.423.164 1.058.36 2.229.413 1.265.058 1.644.07 4.849.07s3.584-.012 4.85-.07c1.17-.053 1.804-.249 2.228-.413.56-.219.96-.477 1.38-.897.398-.387.705-.86.897-1.38.164-.424.36-1.058.413-2.229.058-1.265.07-1.645.07-4.849s-.012-3.584-.07-4.85c-.053-1.17-.249-1.805-.413-2.228a3.725 3.725 0 00-.897-1.38 3.723 3.723 0 00-1.38-.897c-.424-.164-1.058-.36-2.229-.413zm-6.382 13.467a4.002 4.002 0 004.364-6.53 4.003 4.003 0 10-4.364 6.53zM7.64 7.64a6.168 6.168 0 118.722 8.722A6.168 6.168 0 017.64 7.64zm11.897-.888a1.458 1.458 0 10-2-2.121 1.458 1.458 0 002 2.12z"
        fill="#fff"
      />
    </svg>
  );
}
