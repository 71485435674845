interface OurScienceBoxProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}

function OurScienceBox({ icon, title, text }: OurScienceBoxProps) {
  return (
    <div className="flex-1 p-6 border border-transparent border-gradient-bl-orange-purple-white md:p-8 xl:p-10 rounded-xl">
      {icon}
      <h3 className="mt-4 text-xl font-semibold md:mt-6 xl:text-2xl ">{title}</h3>
      <p className="mt-2 text-base leading-6 xl:text-xl xl:leading-8 font-inter">{text}</p>
    </div>
  );
}

export default function OurScience() {
  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-3xl mx-8 text-center md:text-left">
          <h2 className="text-3xl font-semibold text-center md:text-5xl">Our Science</h2>
          <p className="mt-4 text-lg leading-6 text-center md:text-2xl md:leading-10">
            CBT-I focuses on exploring the connection between the way we think, the things we do, and how we sleep. This
            is called a multicomponent treatment, combining several approaches:
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-4 md:mt-12">
        <div className="flex flex-col mx-8 space-y-6 md:flex-row md:space-y-0 md:space-x-8 max-w-7xl">
          <OurScienceBox
            icon={
              <svg className="w-6 h-6 md:w-8 md:h-8 xl:w-10 xl:h-10" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_66_3999)">
                  <path
                    d="M1.3088 18.7149C1.8272 20.3134 3.4028 21.3334 5.084 21.3334H5.15C5.30424 22.0863 5.71363 22.7628 6.30899 23.2486C6.90435 23.7345 7.64917 23.9999 8.4176 24C8.966 24 9.4784 23.862 9.932 23.6244C10.1213 23.5206 10.2788 23.3672 10.3877 23.1808C10.4965 22.9943 10.5526 22.7818 10.55 22.5659V1.2309C10.5524 0.955962 10.4601 0.688577 10.2885 0.473728C10.117 0.258879 9.87665 0.109678 9.608 0.0512235C9.00597 -0.0657175 8.38219 0.0190622 7.8332 0.292439C6.9404 0.719667 6.4172 1.67373 6.4172 2.6662V2.7322C5.74905 2.59582 5.05516 2.66764 4.4291 2.93798C3.80305 3.20831 3.27495 3.66415 2.91604 4.24402C2.55714 4.82388 2.38468 5.49988 2.42194 6.18082C2.45919 6.86177 2.70437 7.51491 3.1244 8.05215C2.1837 8.19955 1.32665 8.67833 0.70789 9.4021C0.0891278 10.1259 -0.250601 11.047 -0.249999 11.9992C-0.249999 13.4129 0.483201 14.6526 1.5896 15.3738C1.0736 16.2991 0.904401 17.4692 1.3088 18.7149ZM20.3756 8.05215C20.7956 7.51491 21.0408 6.86177 21.0781 6.18082C21.1153 5.49988 20.9429 4.82388 20.584 4.24402C20.225 3.66415 19.6969 3.20831 19.0709 2.93798C18.4448 2.66764 17.7509 2.59582 17.0828 2.7322V2.6662C17.0828 1.67493 16.5584 0.719667 15.6656 0.292439C15.1171 0.0189809 14.4936 -0.0658085 13.892 0.0512235C13.6235 0.109884 13.3833 0.259141 13.2117 0.473944C13.0402 0.688747 12.9478 0.956019 12.95 1.2309V22.5647C12.95 23.0027 13.1804 23.4192 13.5692 23.6232C14.0228 23.862 14.534 24 15.0824 24C15.851 23.9998 16.596 23.7342 17.1913 23.2482C17.7867 22.7621 18.196 22.0853 18.35 21.3322H18.4172C20.0972 21.3322 21.674 20.3122 22.1924 18.7137C22.5968 17.468 22.4276 16.2979 21.9116 15.3726C22.4752 15.0085 22.9387 14.509 23.2598 13.9197C23.5809 13.3305 23.7494 12.6703 23.75 11.9992C23.7506 11.047 23.4109 10.1259 22.7921 9.4021C22.1733 8.67833 21.3163 8.19955 20.3756 8.05215Z"
                    fill="url(#paint0_linear_66_3999)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_66_3999"
                    x1="23.75"
                    y1="9.99447e-07"
                    x2="4.73346"
                    y2="27.4521"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF8F5B" />
                    <stop offset="1" stopColor="#536DE2" />
                  </linearGradient>
                  <clipPath id="clip0_66_3999">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            title="Cognitive Intervention"
            text="Learn strategies to challenge unhelpful thinking patterns."
          />
          <OurScienceBox
            icon={
              <svg className="w-6 h-6 md:w-8 md:h-8 xl:w-10 xl:h-10" viewBox="0 0 24 24" fill="none">
                <path
                  d="M13.3528 7.44C14.3999 7.44 15.2859 8.25333 15.2859 9.33333C15.2859 10.4133 14.3999 11.2533 13.3528 11.2533C12.3056 11.2533 11.4465 10.3733 11.4465 9.33333C11.4465 8.29333 12.2654 7.44 13.3528 7.44ZM13.3528 2.78448e-06C18.5615 2.78448e-06 22.75 4.18667 22.75 9.33333C22.75 13.0667 20.5618 16.2533 17.3802 17.7467V24H7.98291V20H6.64045C5.15031 20 3.95552 18.8133 3.95552 17.3333V13.3333H1.94183C1.37799 13.3333 1.0558 12.6667 1.37799 12.2533L3.95552 8.88C4.0738 6.48439 5.11572 4.22586 6.86528 2.5726C8.61484 0.919339 10.9379 -0.00184834 13.3528 2.78448e-06ZM17.3802 9.33333C17.3802 9.12 17.3802 9 17.2996 8.81333L18.4944 7.93333C18.5615 7.88 18.6152 7.69334 18.5615 7.56L17.4875 5.74667C17.4204 5.62667 17.2325 5.56 17.1117 5.62667L15.7826 6.18667C15.541 5.93333 15.2188 5.74667 14.91 5.62667L14.6952 4.25334C14.655 4.06667 14.5878 4 14.3999 4H12.2654C12.1311 4 12.0103 4.06667 12.0103 4.25334L11.8224 5.62667C11.5136 5.74667 11.1914 5.93333 10.9363 6.18667L9.55359 5.62667C9.43277 5.56 9.32537 5.62667 9.24483 5.74667L8.17086 7.56C8.10373 7.74667 8.10373 7.88 8.23798 7.93333L9.36565 8.81333C9.36565 9 9.32537 9.18667 9.32537 9.33333C9.32537 9.50667 9.36565 9.69333 9.36565 9.88L8.23798 10.7467C8.10373 10.8133 8.10373 10.9333 8.17086 11.0667L9.24483 12.9333C9.32537 13.0667 9.43277 13.0667 9.55359 13.0667L10.8826 12.4933C11.1914 12.7467 11.4465 12.88 11.8224 13L12.0103 14.44C12.0103 14.56 12.1311 14.6667 12.2654 14.6667H14.3999C14.5878 14.6667 14.655 14.56 14.6952 14.44L14.91 13C15.2188 12.88 15.541 12.7467 15.7826 12.5067L17.1117 13.0667C17.2325 13.0667 17.4204 13.0667 17.4875 12.9333L18.5615 11.0667C18.6152 10.9333 18.5615 10.8133 18.4944 10.7467L17.3802 9.88V9.33333Z"
                  fill="url(#paint0_linear_66_4005)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_66_4005"
                    x1="22.75"
                    y1="9.99447e-07"
                    x2="3.0909"
                    y2="25.4235"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF8F5B" />
                    <stop offset="1" stopColor="#536DE2" />
                  </linearGradient>
                </defs>
              </svg>
            }
            title="Behavioral Intervention"
            text="Get help identifying and changing habits that stand in the way of a great night of sleep."
          />
          <OurScienceBox
            icon={
              <svg className="w-6 h-6 md:w-8 md:h-8 xl:w-10 xl:h-10" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_66_4011)">
                  <path
                    d="M12 2.67444C10.5225 1.40394 8.3805 1.21494 6.4305 1.40994C4.1595 1.63944 1.8675 2.41794 0.4395 3.06744C0.308474 3.12703 0.197363 3.22307 0.119432 3.34409C0.0415 3.46511 3.98571e-05 3.606 0 3.74994L0 20.2499C3.4743e-05 20.3754 0.0315557 20.4989 0.0916756 20.6091C0.151796 20.7192 0.238593 20.8125 0.344116 20.8804C0.44964 20.9483 0.570517 20.9887 0.695675 20.9978C0.820833 21.0069 0.946271 20.9844 1.0605 20.9324C2.3835 20.3324 4.515 19.6109 6.5805 19.4024C8.694 19.1894 10.4655 19.5329 11.415 20.7179C11.4853 20.8055 11.5743 20.8762 11.6756 20.9248C11.7768 20.9734 11.8877 20.9986 12 20.9986C12.1123 20.9986 12.2232 20.9734 12.3244 20.9248C12.4257 20.8762 12.5147 20.8055 12.585 20.7179C13.5345 19.5329 15.306 19.1894 17.418 19.4024C19.485 19.6109 21.618 20.3324 22.9395 20.9324C23.0537 20.9844 23.1792 21.0069 23.3043 20.9978C23.4295 20.9887 23.5504 20.9483 23.6559 20.8804C23.7614 20.8125 23.8482 20.7192 23.9083 20.6091C23.9684 20.4989 24 20.3754 24 20.2499V3.74994C24 3.606 23.9585 3.46511 23.8806 3.34409C23.8026 3.22307 23.6915 3.12703 23.5605 3.06744C22.1325 2.41794 19.8405 1.63944 17.5695 1.40994C15.6195 1.21344 13.4775 1.40394 12 2.67444Z"
                    fill="url(#paint0_linear_66_4011)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_66_4011"
                    x1="24"
                    y1="1.34375"
                    x2="9.71994"
                    y2="26.5142"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FF8F5B" />
                    <stop offset="1" stopColor="#536DE2" />
                  </linearGradient>
                  <clipPath id="clip0_66_4011">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            title="Educational Intervention"
            text="Understand how the connection between thoughts, feelings, behaviors, and sleep is central to CBT-I."
          />
        </div>
      </div>
    </>
  );
}
